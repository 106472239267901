export default {
  name: "SubcategoriesComponent",
  data() {
    return {
      heightTable: 0,
      bHeightTable: 0,
      tableData: [],
      iCurrentPage: 1,
      iItemsPerPage: 12,
      iNumPages: 0,
      iTotalItems: 0,
      screenHeight: 0,
      texts: "",
    };
  },
  beforeMount() {
    this.$store.commit('setMenuSidebar', {active:false})
    this.texts = FILE.subcategoryTexts[this.selectLanguage];
  },
  mounted() {
    this.fillTable();
  },
  created() {
    window.addEventListener("load",this.contentTableHeight)
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

  },
  destroyed() {
    window.removeEventListener("load", this.contentTableHeight);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    // muestra dialog para editar la subcategoria
    editItem(item) {
      this.$store.commit("setDialogEditSubcategory", {
        active: true,
        arr: item,
      });
    },
    // muestra dialog para eliminar la subcategoria
    deleteItem(item) {
      this.$store.commit("setDelete", {
        active: true,
        title: this.texts.textDialogDelete,
        descipcion: this.texts.delete.textDescription,
        api: `${URI}/api/v1/${this.selectLanguage}/subcategories/${item.sSubcategoryId}`,
      });
    },
    // muestra las subcategorias disponibles
    fillTable() {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/subcategories`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
          iPageNumber: this.iCurrentPage,
          iItemsPerPage: this.iItemsPerPage,
          sSearch: this.sSearch,
          sCategoryTypeId: this.sCategoryId
        },
      })
        .then((response) => {
          this.tableData = response.data.results;
          this.iNumPages = response.data.iNumPages;
          this.iTotalItems = response.data.iTotal;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    handleResize() {
      this.screenHeight = window.innerHeight - 400;
    },
  },
  computed: {
    // set search variable for filter option
    sSearch() {
      return this.$store.state.sSearch;
    },
    sCategoryId() {
      return this.$store.state.sCategoryId;
    },
    refreshTable() {
      return this.$store.state.refresh;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
  },
  watch: {
    refreshTable: function () {
      this.fillTable();
    },
    sSearch: function () {
      this.iCurrentPage = 1;
      this.fillTable();
    },
    sCategoryId: function () {
      this.iCurrentPage = 1;
      this.fillTable();
    },
    iCurrentPage: function () {
      this.fillTable();
    },
    // endDate: function () {
    //   if (this.endDate) {
    //     this.iCurrentPage = 1;
    //     this.fillTable();
    //   }
    // },
    // startDate: function () {
    //   this.iCurrentPage = 1;
    //   this.fillTable();
    // },
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.subcategoryTexts[this.selectLanguage];
      }
      this.fillTable();
    },
  },
};